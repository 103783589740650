/** @jsx jsx */
import { Footer as ThemeFooter, Styled, Flex, jsx } from "theme-ui"
import {Link} from "gatsby";

const Footer = () => (
  <ThemeFooter>
      <div sx={{
          justifyContent: `center`,
          alignItems: `center`,
          mt: 3,
          color: `text`,
          fontWeight: `semibold`,
          a: { color: `text` },
      }}>Interested in a Talk? <Styled.a aria-label="Contact me!" href="mailto:ChrishanDev@gmail.com" sx={{ color: `text`, ":hover": { color: `primary`, textDecoration: `none` }}}>
          Contact me!
      </Styled.a></div>
    <br />
    Copyright &copy; {new Date().getFullYear()}. All rights reserved.
    <br />
      <Styled.a aria-label="Send me an email" href="mailto:ChrishanDev@gmail.com" sx={{ color: `text`, ":hover": { color: `primary`, textDecoration: `none` }}}>
          Email
      </Styled.a>
      {` | `}
      <Styled.a
          as={Link}
          sx={{ color: `text`, ":hover": { color: `primary`, textDecoration: `none` }}}
          key={`impressum`}
          to={`impressum`}
      >
          {`Impressum`}
      </Styled.a>
    <br />
    <Flex
      sx={{
        justifyContent: `center`,
        alignItems: `center`,
        mt: 3,
        color: `text`,
        fontWeight: `semibold`,
        a: { color: `text` },
      }}
    >
      <Styled.a
        aria-label="Link to the theme's GitHub repository"
        sx={{ ml: 2 }}
        href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-emma"
      >
        Theme
      </Styled.a>
      <div sx={{ mx: 1 }}>by</div>
      {` `}
      <Styled.a aria-label="Link to the theme author's website" href="https://www.lekoarts.de/en">
        LekoArts
      </Styled.a>
    </Flex>
  </ThemeFooter>
);

export default Footer
